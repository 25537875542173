import axios from "axios";
import { Component } from "react";
import "./Header.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import jMoment from "moment-jalaali";
import RegisterModal from "./RegisterModal/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

class Header extends Component {
  state = { baseInfo: {}, show: false };

  async componentDidMount() {
    const response = await axios.get(
      "https://kharaei.ir/api/instagram/layout"
    );

    if (response.status === 200) {
      this.setState({ baseInfo: response.data });
    }

  }

  render() {
    return (
      <>
        <header>
          {this.show}
          <div className="container">
            <div className="profile">
              <div className="profile-image">
                {this.state.baseInfo.picture ? (
                  <img
                    src={this.state.baseInfo.picture}
                    alt={this.state.baseInfo.siteTitle}
                  />
                ) : (
                  <Skeleton
                    circle={true}
                    width={150}
                    height={150}
                    style={{ marginBottom: ".67em" }}
                    className="profile-image-skeleton"
                  />
                )}
              </div>
              <div className="profile-user-settings">
                <h1 className="profile-user-name">
                  {this.state.baseInfo.siteTitle ? (
                    this.state.baseInfo.siteTitle
                  ) : (
                    <Skeleton width={200} style={{ marginBottom: ".67em" }} />
                  )}
                </h1>
                {this.state.baseInfo.siteTitle ? (
                  <button
                    className="btn profile-edit-btn"
                    onClick={() => this.setState({ show: true })}
                  >
                    دنبال کردن
                  </button>
                ) : (
                  <Skeleton
                    width={100}
                    style={{ marginRight: "2rem" }}
                    className="profile-edit-btn-skeleton"
                  />
                )}
              </div>
              <div className="profile-stats">
                <ul>
                  <li>
                    {this.state.baseInfo.birthday ? (
                      <>
                        <span className="profile-stat-count">
                          {jMoment().diff(
                            jMoment(
                              this.state.baseInfo.birthday,
                              "jYYYY/jM/jD"
                            ),
                            "years"
                          )}
                        </span>{" "}
                        ساله
                      </>
                    ) : (
                      <Skeleton width={56} />
                    )}
                  </li>
                  <li>
                    {this.state.baseInfo.postCount ? (
                      <>
                        <span className="profile-stat-count">
                          {this.state.baseInfo.postCount}
                        </span>{" "}
                        پست
                      </>
                    ) : (
                      <Skeleton width={56} />
                    )}
                  </li>
                  <li>
                    {this.state.baseInfo.subscriptionCount ||
                    this.state.baseInfo.subscriptionCount === 0 ? (
                      <>
                        <span className="profile-stat-count">
                          {this.state.baseInfo.subscriptionCount}
                        </span>{" "}
                        دنبال کننده
                      </>
                    ) : (
                      <Skeleton width={56} />
                    )}
                  </li>
                </ul>
              </div>
              <div className="profile-bio">
                {this.state.baseInfo.bio ? (
                  <p dangerouslySetInnerHTML={{ __html: this.state.baseInfo.bio }} />
                ) : (
                  <Skeleton width={300} style={{ margin: "1em 0" }} />
                )}
              </div>
            </div>
            {/* End of profile section */}
            <div className="social-infos">
              <ul>
                <li>
                  {this.state.baseInfo.instagram ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.state.baseInfo.instagram}
                      className="social-box"
                    >
                      <canvas></canvas>
                      <div className="social-circle">
                        <i className="fab fa-instagram"></i>
                      </div>
                      <div>
                        <b>اینستاگرام</b>
                      </div>
                    </a>
                  ) : (
                    <>
                      <Skeleton
                        circle={true}
                        width={79}
                        height={79}
                        className="social-circle-skeleton"
                      />
                      <div style={{ textAlign: "center" }}>
                        <Skeleton width={43} style={{ marginTop: "16px" }} />
                      </div>
                    </>
                  )}
                </li>
                <li>
                  {this.state.baseInfo.twitter ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.state.baseInfo.twitter}
                      className="social-box"
                    >
                      <canvas></canvas>
                      <div className="social-circle">
                        <i className="fab fa-twitter"></i>
                      </div>
                      <div>
                        <b>توییتر</b>
                      </div>
                    </a>
                  ) : (
                    <>
                      <Skeleton
                        circle={true}
                        width={79}
                        height={79}
                        className="social-circle-skeleton"
                      />
                      <div style={{ textAlign: "center" }}>
                        <Skeleton width={43} style={{ marginTop: "16px" }} />
                      </div>
                    </>
                  )}
                </li>
                <li>
                  {this.state.baseInfo.linkedin ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.state.baseInfo.linkedin}
                      className="social-box"
                    >
                      <canvas></canvas>
                      <div className="social-circle">
                        <i className="fab fa-linkedin-in"></i>
                      </div>
                      <div>
                        <b>لینکدین</b>
                      </div>
                    </a>
                  ) : (
                    <>
                      <Skeleton
                        circle={true}
                        width={79}
                        height={79}
                        className="social-circle-skeleton"
                      />
                      <div style={{ textAlign: "center" }}>
                        <Skeleton width={43} style={{ marginTop: "16px" }} />
                      </div>
                    </>
                  )}
                </li>
              </ul>
            </div>
          </div>
          {/* End of container */}
        </header>
        <RegisterModal
          onClose={() => this.setState({ show: false })}
          show={this.state.show}
          handleNotif={this.handleNotif}
        ></RegisterModal>

        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>
    );
  }
  handleNotif = (type) => {
    type === "s"
      ? toast.success("با موفقیت ثبت شد.", { theme: "colored" })
      : toast.error("متاسفم، دوباره امتحان کنید.", { theme: "colored" });
    this.setState({ show: false });
  };
}

export default Header;
