import { useEffect, useState } from "react";
import Post from "./Post";
import "./Main.css";
import Loading from "./Loading";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";

const Main = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [lastPage, setLastPage] = useState(0);

  const fetchMoreData = async () => {
    if (page > lastPage) {
      setHasMore(false);
      return;
    }
    const response = await axios.get(`https://kharaei.ir/api/instagram/posts`,
    // , {
    //   params: { PageNumber: page, PageSize: 9 },      
    // }
    );
    setPosts([...posts, ...response.data.posts]);
  };

  useEffect(() => {
    const getPosts = async () => {
      const response = await axios.get(`https://kharaei.ir/api/instagram/posts`
      // , {
      //   params: { PageNumber: 1, PageSize: 9 }
      // }
      );

      setPosts(response.data.posts);
      setLastPage(response.data.lastPage);
      setIsLoading(false);
    };
    getPosts();
  }, []);

  return (
    <main>
      <div className="container">
        <div className="tabs">
          <a href="/">
            <i className="fas fa-th"></i>
            <span>پست‌ها</span>
          </a>
        </div>

        {isLoading ? (
          <div className="gallery">
            <Loading />
          </div>
        ) : (
          <InfiniteScroll
            dataLength={posts.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<div className="loader"></div>}
            endMessage={
              <div style={{ margin: "auto", width: "100%" }}>
                <p style={{ textAlign: "center", marginTop: "45px" }}>
                  <b>شما تمامی پست ها را مشاهده کرده  اید</b>
                </p>
              </div>
            }
          >
            {posts.map((p, index) => (
              <Post key={index} index={index} content={p} />
            ))}
          </InfiniteScroll>
        )}
        {/* End of gallery */}
      </div>
      {/* End of container */}
    </main>
  );
};

export default Main;
